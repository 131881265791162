/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-2",
    "aws_cognito_identity_pool_id": "eu-west-2:7171e062-dd7e-4f3f-91b2-2abdf4fd570a",
    "aws_cognito_region": "eu-west-2",
    "aws_user_pools_id": "eu-west-2_YUCn0GGGf",
    "aws_user_pools_web_client_id": "1qvkgpu0042ea3sea34h0vng4o",
    "oauth": {
        "domain": "amsh-dev.auth.eu-west-2.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://dev.aremystaffhappy.com/auth/redirect/",
        "redirectSignOut": "https://dev.aremystaffhappy.com/logout/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS"
};


export default awsmobile;
